<template>
  <div id="app">
    <NavComponent />
    <b-container>
      <div v-if="messages.length == 0">
        진행된 대화 내용이 없습니다.
      </div>
      <div v-if="messages.length > 0" class="messageContainer">
        <div v-for="(item, index) in messages" v-bind:key="item.message_id">
          <div
            v-if="getUserPosition(item.user.user_id) == 0"
            class="userMessageContainer"
          >
            <div
              v-if="isContinuousMessage(index, item) == false"
              class="userProfileContainer userAlign"
            >
              <img :src="item.user.profile_url" class="profile" />
              <div class="nickname">
                {{ item.user.nickname }} ( {{ item.user.user_id }} )
              </div>
            </div>
            <div class="messageWithTime">
              <div v-if="item.custom_type == 'CHAT_CALLING'">
                <div v-if="item.data.type == 'REQUEST_CALL'">
                  통화 요청
                  <span v-if="item.data.isEnd == true">
                    (통화 완료)
                  </span>
                </div>
                <div v-if="item.data.type == 'ENDED_CALL'">
                  통화 완료 (
                  {{ item.data.totalCallingSeconds }}초 )
                </div>
                <div v-if="item.data.type == 'MISSED_CALL'">
                  통화 요청 (연결 실패)
                </div>
              </div>
              <div v-else>
                <div class="message userMessage">
                  {{ item.message }}
                </div>
              </div>
              <div class="messageTime userAlign">
                {{ new Date(item.created_at) | moment("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
          </div>

          <div
            v-if="getUserPosition(item.user.user_id) == 1"
            class="userMessageContainer targetUserAlign"
          >
            <div
              v-if="isContinuousMessage(index, item) == false"
              class="userProfileContainer"
            >
              <div class="nickname">
                {{ item.user.nickname }} ( {{ item.user.user_id }} )
              </div>
              <img :src="item.user.profile_url" class="profile" />
            </div>
            <div class="messageWithTime">
              <div v-if="item.custom_type == 'CHAT_CALLING'">
                <div v-if="item.data.type == 'REQUEST_CALL'">
                  통화 요청
                  <span v-if="item.data.isEnd == true">
                    (통화 완료)
                  </span>
                </div>
                <div v-if="item.data.type == 'ENDED_CALL'">
                  통화 완료 (
                  {{ item.data.totalCallingSeconds }}초 )
                </div>
                <div v-if="item.data.type == 'MISSED_CALL'">
                  통화 요청 (연결 실패)
                </div>
              </div>
              <div v-else>
                <div class="message targetUserMessage">
                  {{ item.message }}
                </div>
              </div>
              <div class="messageTime">
                {{ new Date(item.created_at) | moment("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isEndConversation == true">
        더 이상 대화 내용이 없습니다.
      </div>
      <div>
        <b-button
          variant="info"
          @click="getSendbirdMessages(messageTs)"
          :disabled="isEndConversation === true"
        >
          다음 내역 보기
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";

export default {
  name: "SendbirdMessage",
  components: {
    NavComponent,
  },
  data() {
    return {
      channelUrl: this.$route.params.channelUrl,
      messageTs: 0,
      messages: [],
      isEndConversation: false,
      userPosition: { users: [], positions: {} },
    };
  },
  async created() {
    // getSendbirdMessages를 초기 로딩에 사용
    await this.getSendbirdMessages();
  },
  methods: {
    async getSendbirdMessages(messageTs = "") {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API}/management/sendbird-messages/`, {
            params: {
              channel_url: this.$route.params.channelUrl,
              message_ts: messageTs
            },
            headers: { 
              Authorization: this.$session.get("token") 
            }
          }
        );

        const { messages } = response.data.data;
        
        // 메시지 데이터 변환
        const formattedMessages = messages.map(msg => ({
          ...msg,
          data: JSON.parse(msg.data)
        }));

        // 초기 로딩인 경우 messages를 덮어쓰고, 그 외에는 추가
        if (!messageTs) {
          this.messages = formattedMessages;
        } else {
          this.messages = [...this.messages, ...formattedMessages];
        }

        // 다음 페이지 처리
        if (formattedMessages.length > 0) {
          this.messageTs = this.messages[this.messages.length - 1].created_at;
        } else {
          this.isEndConversation = true;
        }

      } catch (error) {
        
        this.$toast.error(
          error.response?.data?.data?.error?.[0]?.code === 400201
            ? "대화 내역이 없거나 삭제되었습니다."
            : `네트워크 오류: ${error.message}`,
          {
            position: 'top',
            duration: 2400
          }
        );
      }
    },
    getUserPosition(user_email) {
      if (this.userPosition.positions[user_email] === undefined) {
        if (this.userPosition.users.length == 0) {
          this.userPosition.positions[user_email] = 0;
        } else {
          this.userPosition.positions[user_email] = 1;
        }
        this.userPosition.users.push(user_email);
      }

      return this.userPosition.positions[user_email];
    },
    isContinuousMessage(index, message) {
      if (index === 0) {
        return false;
      }

      if (this.messages[index - 1].custom_type !== "") {
        return false;
      }

      if (this.messages[index - 1].user.user_id === message.user.user_id) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  text-align: center;
  padding: 20px 0;
  width: 30%;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.userMessageContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 6px;
}

.userProfileContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.profile {
  width: 30px;
  border-radius: 15px;
}

.nickname {
  margin-top: auto;
  font-size: 14px;
}

.messageWithTime {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.message {
  border: 1px solid black;
  max-width: 500px;
  word-break: break-all;
  word-wrap: break-word;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  text-align: left;
}

.userMessage {
  background-color: white;
}

.targetUserMessage {
  background-color: yellow;
}

.messageTime {
  font-size: 10px;
  margin-top: auto;
  word-break: keep-all;
}

.userAlign {
  align-items: flex-start;
  text-align: left;
}

.targetUserAlign {
  align-items: flex-end;
  text-align: right;
}
</style>
